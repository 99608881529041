import authAxios from './authAxios';

export const syncProgress = async (user, progressData) => {
    // Always update localStorage
    const { mangaTitle, chapter, mangaBaseUrl, timestamp, isBookmarked } = progressData;
    
    // Update reading history
    const existingHistory = JSON.parse(localStorage.getItem('readingHistory') || '[]');
    const updatedHistory = [
        { title: mangaTitle, chapter, mangaBaseUrl, timestamp },
        ...existingHistory.filter(item => item.mangaBaseUrl !== mangaBaseUrl)
    ].slice(0, 10);
    localStorage.setItem('readingHistory', JSON.stringify(updatedHistory));

    // Update bookmarks if needed
    if (isBookmarked !== undefined) {
        const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
        const updatedBookmarks = isBookmarked
            ? [...bookmarks, { title: mangaTitle, chapter, mangaBaseUrl, timestamp }]
            : bookmarks.filter(b => !(b.mangaBaseUrl === mangaBaseUrl && b.chapter === chapter));
        localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
    }

    // If user is logged in, sync with server
    if (user) {
        try {
            await authAxios.post('/user-progress', progressData, {
                headers: {
                    'x-user-id': user.id
                }
            });
        } catch (error) {
            console.error('Failed to sync progress with server:', error);
        }
    }
};

export const loadUserProgress = async (user) => {
    if (!user) return;

    try {
        const response = await authAxios.get('/user-progress');
        const serverData = response.data;

        // Merge server data with local data
        const localHistory = JSON.parse(localStorage.getItem('readingHistory') || '[]');
        const localBookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');

        // Merge and sync back to server if local data is newer
        serverData.forEach(async (serverItem) => {
            const localHistoryItem = localHistory.find(item => item.mangaBaseUrl === serverItem.manga_base_url);
            if (localHistoryItem && localHistoryItem.timestamp > serverItem.last_read_timestamp) {
                await syncProgress(user, {
                    mangaTitle: localHistoryItem.title,
                    chapter: localHistoryItem.chapter,
                    mangaBaseUrl: localHistoryItem.mangaBaseUrl,
                    timestamp: localHistoryItem.timestamp,
                    isBookmarked: localBookmarks.some(b => b.mangaBaseUrl === serverItem.manga_base_url)
                });
            }
        });
    } catch (error) {
        console.error('Failed to load user progress:', error);
    }
};
