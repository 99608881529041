import { useState, useEffect, useCallback, useRef } from 'react';
import authAxios from '../utils/authAxios';
import { syncProgress as syncProgressUtil } from '../utils/progressSync';

export const useReadingProgress = (user) => {
  const [isLoading, setIsLoading] = useState(false);
  const syncTimeoutRef = useRef(null);

  // Debounced sync function
  const debouncedSync = useCallback((progressData) => {
    // Clear any existing timeout
    if (syncTimeoutRef.current) {
      clearTimeout(syncTimeoutRef.current);
    }

    // Set new timeout
    syncTimeoutRef.current = setTimeout(async () => {
      try {
        await syncProgressUtil(user, progressData);
      } catch (error) {
        console.error('Failed to sync progress:', error);
      }
    }, 2000); // 2 second delay
  }, [user]);

  // Fetch user progress from server
  useEffect(() => {
    let isMounted = true;
    
    const fetchUserProgress = async () => {
      if (!user) return;

      try {
        setIsLoading(true);
        const response = await authAxios.get('/user-progress', {
          headers: {
            'x-user-id': user.id
          }
        });

        if (!isMounted) return;

        // Get current localStorage data
        const savedHistory = JSON.parse(localStorage.getItem('readingHistory') || '[]');
        const savedBookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
        let historyUpdated = false;
        let bookmarksUpdated = false;

        // Update localStorage if server has more recent data
        response.data.forEach(serverItem => {
          const localHistoryItem = savedHistory.find(item => 
            item.mangaBaseUrl === serverItem.manga_base_url
          );

          // Convert chapter numbers to numbers for comparison
          const serverChapter = Number(serverItem.last_chapter);
          const localChapter = localHistoryItem ? Number(localHistoryItem.chapter) : 0;

          if (!localHistoryItem || serverChapter > localChapter) {
            // Update or add to history
            const updatedHistory = savedHistory.filter(item => 
              item.mangaBaseUrl !== serverItem.manga_base_url
            );
            updatedHistory.unshift({
              mangaBaseUrl: serverItem.manga_base_url,
              title: serverItem.manga_title,
              chapter: serverItem.last_chapter,
              timestamp: new Date(serverItem.last_read_timestamp).getTime()
            });
            localStorage.setItem('readingHistory', JSON.stringify(updatedHistory));
            historyUpdated = true;
          }

          // Handle bookmarks similarly
          if (serverItem.is_bookmarked) {
            const localBookmark = savedBookmarks.find(item => 
              item.mangaBaseUrl === serverItem.manga_base_url
            );
            if (!localBookmark) {
              savedBookmarks.unshift({
                mangaBaseUrl: serverItem.manga_base_url,
                title: serverItem.manga_title,
                chapter: serverItem.last_chapter,
                timestamp: new Date(serverItem.last_read_timestamp).getTime()
              });
              bookmarksUpdated = true;
            }
          }
        });

        if (bookmarksUpdated) {
          localStorage.setItem('bookmarks', JSON.stringify(savedBookmarks));
        }

        // Trigger storage event manually to update UI
        if (historyUpdated || bookmarksUpdated) {
          window.dispatchEvent(new Event('storage'));
        }

      } catch (error) {
        console.error('Error fetching user progress:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchUserProgress();

    // Cleanup function
    return () => {
      isMounted = false;
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
    };
  }, [user]);

  // Sync progress function with local storage update and debounced server sync
  const syncProgress = useCallback(async (progressData) => {
    // Immediately update localStorage
    try {
      const savedHistory = JSON.parse(localStorage.getItem('readingHistory') || '[]');
      const updatedHistory = savedHistory.filter(item => 
        item.mangaBaseUrl !== progressData.mangaBaseUrl
      );
      updatedHistory.unshift({
        mangaBaseUrl: progressData.mangaBaseUrl,
        title: progressData.mangaTitle,
        chapter: progressData.chapter,
        timestamp: progressData.timestamp
      });
      localStorage.setItem('readingHistory', JSON.stringify(updatedHistory));

      // Update bookmarks if needed
      if (progressData.isBookmarked !== undefined) {
        const savedBookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
        const bookmarkExists = savedBookmarks.some(item => 
          item.mangaBaseUrl === progressData.mangaBaseUrl
        );

        if (progressData.isBookmarked && !bookmarkExists) {
          savedBookmarks.unshift({
            mangaBaseUrl: progressData.mangaBaseUrl,
            title: progressData.mangaTitle,
            chapter: progressData.chapter,
            timestamp: progressData.timestamp
          });
          localStorage.setItem('bookmarks', JSON.stringify(savedBookmarks));
        } else if (!progressData.isBookmarked && bookmarkExists) {
          const updatedBookmarks = savedBookmarks.filter(item => 
            item.mangaBaseUrl !== progressData.mangaBaseUrl
          );
          localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
        }
      }

      // Trigger storage event
      window.dispatchEvent(new Event('storage'));

      // Debounce server sync
      debouncedSync(progressData);
    } catch (error) {
      console.error('Error updating local storage:', error);
    }
  }, [debouncedSync]);

  return { isLoading, syncProgress };
};
