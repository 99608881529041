import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchTrendingManga } from './api';
import { useAuth } from './context/AuthContext';
import { useReadingProgress } from './hooks/useReadingProgress';
import { FaBookOpen, FaBookmark, FaChevronDown, FaChevronUp, FaSearch, FaBook } from 'react-icons/fa';
import Footer from './components/Footer/Footer';
import './css/HomePage.css';

const HomePage = () => {
  const [trendingManga, setTrendingManga] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [readingHistory, setReadingHistory] = useState([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [isBookmarksOpen, setIsBookmarksOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isLoading: isSyncLoading } = useReadingProgress(user);

  // Load initial data and handle user changes
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        // Load trending manga
        const manga = await fetchTrendingManga();
        setTrendingManga(manga);

        // Always load from localStorage first
        const savedHistory = localStorage.getItem('readingHistory');
        if (savedHistory) {
          const parsedHistory = JSON.parse(savedHistory);
          setReadingHistory(parsedHistory.sort((a, b) => b.timestamp - a.timestamp));
        }

        const savedBookmarks = localStorage.getItem('bookmarks');
        if (savedBookmarks) {
          const parsedBookmarks = JSON.parse(savedBookmarks);
          setBookmarks(parsedBookmarks.sort((a, b) => b.timestamp - a.timestamp));
        }
      } catch (err) {
        setError('Failed to load trending manga. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [user]); // Added user as dependency to reload when login status changes

  // Update state when localStorage changes (handles both local updates and server syncs)
  useEffect(() => {
    const handleStorageChange = (e) => {
      // Only handle our specific storage keys
      if (e.key === 'readingHistory' || e.key === null) {
        const savedHistory = localStorage.getItem('readingHistory');
        if (savedHistory) {
          const parsedHistory = JSON.parse(savedHistory);
          setReadingHistory(parsedHistory.sort((a, b) => b.timestamp - a.timestamp));
        }
      }

      if (e.key === 'bookmarks' || e.key === null) {
        const savedBookmarks = localStorage.getItem('bookmarks');
        if (savedBookmarks) {
          const parsedBookmarks = JSON.parse(savedBookmarks);
          setBookmarks(parsedBookmarks.sort((a, b) => b.timestamp - a.timestamp));
        }
      }
    };

    // Initial load from localStorage
    handleStorageChange({ key: null });

    // Listen for storage events (for cross-tab synchronization)
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Empty dependency array since we're only setting up listeners

  const handleStartReading = (mangaUrl) => {
    const baseUrl = mangaUrl.replace(/\/$/, '');
    navigate(`/view/1`, { state: { mangaBaseUrl: baseUrl } });
  };

  const toggleHistoryDropdown = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  const toggleBookmarksDropdown = () => {
    setIsBookmarksOpen(!isBookmarksOpen);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <>
      <div className="content">
        <h1>Welcome to Library of Legends</h1>
        
        {/* Show loading indicator while syncing with server */}
        {isSyncLoading && user && (
          <div className="sync-indicator">Syncing your progress...</div>
        )}

        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Seek your legendary saga..."
            className="search-input"
          />
          <button type="submit" className="search-button">
            <FaSearch />
          </button>
        </form>

        <div className="dropdowns-container">
          {readingHistory.length > 0 && (
            <div className="dropdown-wrapper">
              <button className="history-dropdown-button" onClick={toggleHistoryDropdown}>
                <FaBookOpen className="icon" />
                Resume Your Epic Journey
                {isHistoryOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
              </button>
              {isHistoryOpen && (
                <div className="reading-history-dropdown">
                  {readingHistory.map((item, index) => (
                    <Link
                      key={index}
                      to={`/view/${item.chapter}`}
                      state={{ mangaBaseUrl: item.mangaBaseUrl }}
                      onClick={() => setIsHistoryOpen(false)}
                      className="history-item"
                    >
                      <div className="manga-title">{item.title}</div>
                      <div className="chapter-info">Chapter {item.chapter} of the Legend</div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
          {bookmarks.length > 0 && (
            <div className="dropdown-wrapper">
              <button className="history-dropdown-button" onClick={toggleBookmarksDropdown}>
                <FaBookmark className="icon" />
                Your Legendary Bookmarks
                {isBookmarksOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
              </button>
              {isBookmarksOpen && (
                <div className="reading-history-dropdown">
                  {bookmarks.map((item, index) => (
                    <Link
                      key={index}
                      to={`/view/${item.chapter}`}
                      state={{ mangaBaseUrl: item.mangaBaseUrl }}
                      onClick={() => setIsBookmarksOpen(false)}
                      className="history-item"
                    >
                      <div className="manga-title">{item.title}</div>
                      <div className="chapter-info">Chapter {item.chapter} of the Saga</div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {error && <p className="error-message">{error}</p>}
        <h2>Embark on a Legend</h2>
        {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <div className="trending-container">
            {trendingManga.map((manga, index) => (
              <div 
                key={index} 
                className="manga-card"
                onClick={() => handleStartReading(manga.url)}
              >
                <div className="manga-title-wrapper">
                  <FaBook className="manga-icon" />
                  <h3 className="manga-title">{manga.title}</h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
