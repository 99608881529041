import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchChapterData, fetchAvailableChapters } from './api';
import { ChapterHeader, ChapterNavigation, MangaImageViewer, LoadingSpinner, BottomNavigation } from './ViewComponents';
import { useReadingProgress } from './hooks/useReadingProgress';
import { useAuth } from './context/AuthContext';
import './css/ViewPage.css';
import authAxios from './utils/authAxios';

const ViewPage = ({ onLogout, getMangaBaseUrl }) => {
  const [currentChapter, setCurrentChapter] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [availableChapters, setAvailableChapters] = useState([]);
  const [mangaBaseUrl, setMangaBaseUrl] = useState('');
  const [chapterMetadata, setChapterMetadata] = useState(null);
  const [mangaTitle, setMangaTitle] = useState('');
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [nextChapterImages, setNextChapterImages] = useState([]);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [navTimeout, setNavTimeout] = useState(null);
  const { user } = useAuth();
  const { syncProgress } = useReadingProgress(user);

  const { chapterNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleScreenTouch = useCallback(() => {
    if (navTimeout) {
      clearTimeout(navTimeout);
    }
    setIsNavVisible(true);
    const timeout = setTimeout(() => {
      setIsNavVisible(false);
    }, 3000);
    setNavTimeout(timeout);
  }, [navTimeout]);

  const handleBookmark = async () => {
    const newBookmarkState = !isBookmarked;
    setIsBookmarked(newBookmarkState);
    try {
      await syncProgress({
        mangaTitle,
        chapter: currentChapter?.number,
        mangaBaseUrl,
        timestamp: Date.now(),
        isBookmarked: newBookmarkState
      });
    } catch (error) {
      console.error('Failed to sync bookmark:', error);
      setIsBookmarked(!newBookmarkState); // Revert on error
    }
  };

  const handleShare = () => {
    const mangaPath = mangaBaseUrl.replace(/^https?:\/\/[^/]+\/manga\//, '');
    const shareUrl = `${window.location.origin}/view/${currentChapter?.number}?manga=${mangaPath}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  const handlePreviousChapter = () => {
    const currentIndex = availableChapters.findIndex(ch => ch.number === currentChapter?.number);
    if (currentIndex > 0) {
      const previousChapter = availableChapters[currentIndex - 1];
      navigate(`/view/${previousChapter.number}`, { state: { mangaBaseUrl } });
    }
  };

  const handleNextChapter = () => {
    const currentIndex = availableChapters.findIndex(ch => ch.number === currentChapter?.number);
    if (currentIndex < availableChapters.length - 1) {
      const nextChapter = availableChapters[currentIndex + 1];
      if (nextChapterImages.length > 0) {
        setImageUrls(nextChapterImages);
        setLoadedImages(nextChapterImages);
        setNextChapterImages([]);
      }
      navigate(`/view/${nextChapter.number}`, { state: { mangaBaseUrl } });
    }
  };

  const handleChapterSelect = (event) => {
    const selectedChapterNumber = event.target.value;
    navigate(`/view/${selectedChapterNumber}`, { state: { mangaBaseUrl } });
  };

  const handleBackToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const determineBaseUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const baseUrlFromSearch = searchParams.get('manga');

    if (baseUrlFromSearch) {
      const cleanedBaseUrl = baseUrlFromSearch.replace(/^h1\//, '');
      return `https://www.mangaread.org/manga/${cleanedBaseUrl}`;
    }

    if (location.state && location.state.mangaBaseUrl) {
      return location.state.mangaBaseUrl;
    }

    return null;
  }, [location.search, location.state]);

  useEffect(() => {
    const baseUrl = determineBaseUrl();
    if (baseUrl && baseUrl !== mangaBaseUrl) {
      console.log('Setting mangaBaseUrl:', baseUrl);
      setMangaBaseUrl(baseUrl);
    }
  }, [determineBaseUrl, location.state, mangaBaseUrl]);

  useEffect(() => {
    const titleMatch = mangaBaseUrl.match(/\/manga\/([^/]+)/);
    if (titleMatch && titleMatch[1]) {
      const decodedTitle = decodeURIComponent(titleMatch[1].replace(/-/g, ' '));
      setMangaTitle(decodedTitle.charAt(0).toUpperCase() + decodedTitle.slice(1));
    }
  }, [mangaBaseUrl]);

  useEffect(() => {
    if (currentChapter && mangaBaseUrl && mangaTitle) {
      const timestamp = Date.now();
      const progressData = {
        mangaTitle,
        chapter: currentChapter.number,
        mangaBaseUrl,
        timestamp,
        isBookmarked
      };

      syncProgress(progressData);
    }
  }, [currentChapter, mangaBaseUrl, mangaTitle, isBookmarked, syncProgress]);

  const loadChapterData = useCallback(async (chapterUrl) => {
    try {
      const { heading, imageUrls } = await fetchChapterData(chapterUrl);
      setChapterMetadata({ title: heading, chapterNumber });
      setImageUrls(imageUrls);

      const newLoadedImages = new Array(imageUrls.length).fill(null);
      setLoadedImages(newLoadedImages);

      imageUrls.forEach((imgUrl, index) => {
        const img = new Image();
        img.src = imgUrl;
        img.onload = () => {
          setLoadedImages(prevImages => {
            const newImages = [...prevImages];
            newImages[index] = imgUrl;
            return newImages;
          });
        };
      });
    } catch (err) {
      setError('Error loading chapter content. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [chapterNumber]);

  const preloadNextChapter = useCallback(async () => {
    const currentIndex = availableChapters.findIndex(ch => ch.number === currentChapter?.number);
    if (currentIndex < availableChapters.length - 1) {
      const nextChapter = availableChapters[currentIndex + 1];
      try {
        const { imageUrls } = await fetchChapterData(nextChapter.url);
        setNextChapterImages(imageUrls);
        
        imageUrls.forEach(url => {
          const img = new Image();
          img.src = url;
        });
      } catch (error) {
        console.error('Error preloading next chapter:', error);
      }
    }
  }, [currentChapter, availableChapters]);

  useEffect(() => {
    preloadNextChapter();
  }, [preloadNextChapter]);

  const loadChapterMetadata = useCallback(async () => {
    if (mangaBaseUrl && chapterNumber) {
      try {
        setLoading(true);
        setError('');
        const chapters = await fetchAvailableChapters(mangaBaseUrl);
        setAvailableChapters(chapters);
        const foundChapter = chapters.find(ch => 
          ch.number === chapterNumber || 
          ch.number.startsWith(chapterNumber) ||
          ch.number.includes(chapterNumber)
        );
        if (foundChapter) {
          setCurrentChapter(foundChapter);
          await loadChapterData(foundChapter.url);
        } else {
          setError('Chapter not found');
        }
      } catch (err) {
        setError('Error loading chapter information. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  }, [mangaBaseUrl, chapterNumber, loadChapterData]);

  useEffect(() => {
    if (mangaBaseUrl) {
      loadChapterMetadata();
    }
  }, [mangaBaseUrl, loadChapterMetadata]);

  useEffect(() => {
    const loadInitialState = async () => {
      if (!user || !mangaBaseUrl || !currentChapter) return;

      // Save current chapter to local storage
      localStorage.setItem('currentChapter', JSON.stringify({
        mangaBaseUrl,
        chapterNumber: currentChapter.number,
        isBookmarked
      }));

      try {
        const response = await authAxios.get('/user-progress', {
          headers: {
            'x-user-id': user.id
          },
          timeout: 10000 // Extend timeout to 10 seconds
        });

        const data = response.data;
        const currentMangaProgress = data.find(
          item => item.manga_base_url === mangaBaseUrl
        );

        if (currentMangaProgress) {
          setIsBookmarked(currentMangaProgress.is_bookmarked);
          
          if (currentChapter.number === '1' && currentMangaProgress.last_chapter !== '1') {
            navigate(`/view/${currentMangaProgress.last_chapter}`, {
              state: { mangaBaseUrl }
            });
          }
        }
      } catch (error) {
        console.error('Failed to load user progress:', error);
      }
    };

    // Call loadInitialState after everything else is loaded
    if (mangaBaseUrl && currentChapter) {
      loadInitialState();
    }
  }, [user, mangaBaseUrl, currentChapter, navigate, isBookmarked]);

  useEffect(() => {
    const syncUserProgress = async () => {
      if (!user || !mangaBaseUrl || !currentChapter) return;

      try {
        await syncProgress({
          mangaTitle,
          chapter: currentChapter.number,
          mangaBaseUrl,
          timestamp: Date.now(),
          isBookmarked
        });
      } catch (error) {
        console.error('Failed to sync user progress:', error);
      }
    };

    // Sync user progress after everything else is loaded
    if (mangaBaseUrl && currentChapter) {
      syncUserProgress();
    }
  }, [user, mangaBaseUrl, currentChapter, mangaTitle, isBookmarked, syncProgress]);

  useEffect(() => {
    return () => {
      if (navTimeout) {
        clearTimeout(navTimeout);
      }
    };
  }, [navTimeout]);

  return (
    <div className="view-page" onClick={handleScreenTouch} onTouchStart={handleScreenTouch}>
      <ChapterHeader 
        title={chapterMetadata?.title || 'Loading...'}
        isBookmarked={isBookmarked}
        onBookmark={handleBookmark}
        onShare={handleShare}
      />
      <main className="content">
        {error && <p className="error-message">{error}</p>}
        <ChapterNavigation
          chapter={chapterMetadata?.chapterNumber}
          availableChapters={availableChapters}
          onPreviousChapter={handlePreviousChapter}
          onNextChapter={handleNextChapter}
          onChapterSelect={handleChapterSelect}
        />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <MangaImageViewer images={loadedImages.length > 0 ? loadedImages : imageUrls} />
        )}
      </main>
      {isNavVisible && (
        <BottomNavigation
          onPreviousChapter={handlePreviousChapter}
          onNextChapter={handleNextChapter}
          onBackToTop={handleBackToTop}
          isPreviousDisabled={!currentChapter || availableChapters.findIndex(ch => ch.number === currentChapter.number) === 0}
          isNextDisabled={!currentChapter || availableChapters.findIndex(ch => ch.number === currentChapter.number) === availableChapters.length - 1}
        />
      )}
    </div>
  );
};

export default ViewPage;
